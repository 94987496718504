import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/Book/01'
    },
    {
        path: '/Book/:id?/:verse?',
        name: 'Book',
        props: true,
        component: () => import(/* webpackChunkName: "book" */ '../views/Book.vue')
    },
    {
        path: '/Dictionary',
        name: 'Custom Dictionary',
        props: true,
        component: () => import(/* webpackChunkName: "dictionary" */ '../views/Dictionary.vue')
    },
    {
        path: '/SearchRefs',
        name: 'Search',
        props: true,
        component: () => import(/* webpackChunkName: "dictionary" */ '../views/SearchRefs.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
