<template>
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-2 fixed-top">
            <div class="container-fluid">
                <router-link :to="$route.path" class="navbar-brand">{{currentBook}}</router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-lg-0">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Old Testament
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li v-for="(item, index) in booknames.slice(0,39)" :key="index" @click="collapseMenu">
                                    <router-link :to="`/Book/${(index+1).toString().padStart(2,'0')}`" class="dropdown-item">{{item}}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                New Testament
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="navbarDropdown2">
                                <li v-for="(item, index) in booknames.slice(39,66)" :key="index" @click="collapseMenu">
                                    <router-link :to="`/Book/${String(index+40).padStart(2,'0')}`" class="dropdown-item">{{item}}</router-link>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item" @click="collapseMenu">
                            <router-link to="/Dictionary" class="nav-link">Custom Dictionary</router-link>
                        </li>
                        <li class="nav-item" @click="collapseMenu">
                            <router-link to="/SearchRefs" class="nav-link">Search</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div style="padding-top: 60px;"><router-view /></div>
    </div>
</template>
<style>
    .vm--container {
        z-index: 1030 !important;
    }

    .vm--block-scroll {
        overflow: hidden !important;
        overflow-y: scroll !important;
        width: 100% !important;
    }

    .dropdown-menu.show {
        max-height: 60vh;
        overflow-y: auto;
    }

    .btn, .nav-link {
        text-transform: none !important;
    }
</style>
<script>
    export default {
        computed: {
            id() {
                const vm = this;
                return vm.$route.params.id;
            },
            currentBook() {
                const vm = this;
                if (!(vm.id && vm.id.length)) return vm.$route.name;
                const idNum = +vm.id;
                return vm.booknames[idNum - 1];
            }
        },
        mounted() {
            const asset_ver = process.env.VUE_APP_VERSION;
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    for (let registration of registrations) {
                        if (registration && registration.active && registration.active.scriptURL)
                            if (!registration.active.scriptURL.endsWith(`?v=${asset_ver}`))
                                registration.unregister();
                    }
                }).catch(function (err) {
                    console.log('Service Worker de-registration failed: ', err);
                });
                navigator.serviceWorker.register(`/service-worker.js?v=${asset_ver}`, { scope: '/' });
            }
        },
        data() {
            return {
                booknames: ["Genesis", "Exodus", "Leviticus", "Numbers", "Deuteronomy", "Joshua", "Judges", "Ruth", "1 Samuel", "2 Samuel", "1 Kings", "2 Kings", "1 Chronicles", "2 Chronicles", "Ezra", "Nehemiah", "Esther", "Job", "Psalms", "Proverbs", "Ecclesiastes", "Song of Solomon", "Isaiah", "Jeremiah", "Lamentations", "Ezekiel", "Daniel", "Hosea", "Joel", "Amos", "Obadiah", "Jonah", "Micah", "Nahum", "Habakkuk", "Zephaniah", "Haggai", "Zechariah", "Malachi", "Matthew", "Mark", "Luke", "John", "Acts", "Romans", "1 Corinthians", "2 Corinthians", "Galatians", "Ephesians", "Philippians", "Colossians", "1 Thessalonians", "2 Thessalonians", "1 Timothy", "2 Timothy", "Titus", "Philemon", "Hebrews", "James", "1 Peter", "2 Peter", "1 John", "2 John", "3 John", "Jude", "Revelation"],
            }
        },
        methods: {
            collapseMenu() {
                setTimeout(() => { document.querySelector('.navbar-toggler').click(); }, 100);
            }
        }
    }
</script>
